<template>
    <a-layout class="yj-conten-div">
        <a-layout-content>
            <div class="yj-conten" style="background:#fff;padding:20px 20px 0;min-height:calc(100% - 48px)">
                <a-row class="yj-form">
                    <a-col :span="24">
                        <label>客户 :</label>
                        <a-input id="name"
                                 contextmenu="2455"
                                 :value="name"
                                 style="width:260px"
                                 placeholder="请输入客户名称"
                                 @change="name_change"></a-input>

                        <label style="margin-left:10px">介绍人 :</label>
                        <a-input id="introducer"
                                 contextmenu="2455"
                                 :value="introducer"
                                 style="width:260px"
                                 placeholder="请输入介绍人名称"
                                 @change="introducer_change"></a-input>

                        <label style="margin-left:10px">分享人 :</label>
                        <a-input id="introducer"
                                 contextmenu="2455"
                                 :value="SharedName"
                                 style="width:260px"
                                 placeholder="请输入推荐人名称"
                                 @change="SharedName_change"></a-input>
                        <label>访问时间 :</label>
                        <a-range-picker style="width:260px;"
                                        :value="Time"
                                        @change="handleChange" />
                        <a-button type="primary" @click="getTableData">查询</a-button>
                    </a-col>
                </a-row>
                <a-table bordered :columns="columns"
                         rowKey="ID"
                         :data-source="tableData"
                         :pagination="pagination"
                         @change="pagerChange"
                         class="yj-table-img">
                    <span slot="imgs" slot-scope="text,record">
                        <img class="imgPreview" :src="record.UHeadImg" />
                    </span>
                    <span slot="name_slot" slot-scope="text,record">
                        <a @click="jump(record)">{{record.Name}}</a>
                    </span>
                    <span slot="Introducer" slot-scope="text,record">
                        <a @click="PartnerJump(record)">{{record.IntroducerName}}</a>
                    </span>
                    <span slot="Share" slot-scope="text,record">
                        <a @click="ShareJump(record)">{{record.ShareID==null?"无":record.ShareName}}</a>
                    </span>

                    <template v-slot:GPStemplate="text,record">

                        <p>距离{{getdistance(record.Lat,record.Lng)}}</p>
                        <p class="addrs">{{record.AddressStr}}</p>
                    </template>

                    <span slot="LastGPS" slot-scope="text,record">
                        {{getdistance(record.Lat,record.Lng)}}
                    </span>
                </a-table>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import moment from 'moment';
    export default {
        name: "Operations_QRcodeRecordList",
        data() {
            return {
                Time: [],
                BeginTime: "",
                EndTime: "",
                IntroducerID: "",
                UserID:"",
                ShareID: "",
                tableData: [],
                name: "",
                introducer:"",
                SharedName: "",
                SitetableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                columns: [
                    {
                        title: "头像",
                        align: "center",
                        scopedSlots: { customRender: "imgs" },
                        width: 100
                    },
                    {
                        title: "姓名",
                        width: 150,
                        scopedSlots: { customRender: "name_slot" },
                    },
                    {
                        title: "电话",
                        width: 150,
                        customRender: (text, item) => {
                            var rt = "";
                            if (item.Phone.length == 11) {
                                rt = item.Phone;
                            }
                            return rt;
                        }
                    },
                    {
                        title: "介绍人",
                        width: 150,
                        scopedSlots: { customRender: "Introducer" }
                    },
                    {
                        title: "分享人",
                        width: 150,
                        scopedSlots: { customRender: "Share" }
                    },
                    {
                        title: "地点",
                        width: 250,
                        dataIndex: "AddressStr",
                    },
                    {
                        title: "位置距离",
                        dataIndex: "AddressStr",
                        width: 250,
                        scopedSlots: { customRender: "GPStemplate" }
                    },
                    {
                        title: "访问时间",
                        width: 250,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss');
                        }
                    }
                ],
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
                console.log("name")
                console.log(self.name);
            },
            introducer_change: function (item) {
                var self = this;
                self.introducer = item.target.value;
                console.log("introducer")
                console.log(self.introducer);
            },
            SharedName_change: function (item) {
                var self = this;
                self.SharedName = item.target.value;
                console.log("SharedName")
                console.log(self.SharedName);
            },
            handleChange(value) {
                var self = this;
                self.Time = value;
                if (value.length == 0) {
                    self.BeginTime = "";
                    self.EndTime = "";
                } else {
                    self.BeginTime = util.formatDate(self.Time[0]._d);
                    self.EndTime = util.formatDate(self.Time[1]._d);
                }
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations/NewGetQRcodeRecordListMvc",
                    data: {
                        pageIndex: self.pagination.current,
                        BeginDate: self.BeginTime,
                        EndDate: self.EndTime,
                        Name: self.name,
                        ShareName:self.SharedName,
                        IntroducerName: self.introducer,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            jump: function (data) {
                var self = this;
                self.$router.push({
                    name: "OperationsDetail", query: {
                        ID: data.VisiterID
                    }
                })
            },
            PartnerJump: function (data) {
                var self = this;
                self.$router.push({
                    name: "PartnerList_PartnerDetail", query: {
                        ID: data.IntroducerID
                    }
                })
            },
            ShareJump: function (data) {
                var self = this;
                if (data.ShareID != null) {
                    self.$router.push({
                        name: "OperationsDetail", query: {
                            ID: data.ShareID
                        }
                    })
                }
            },
            getSite: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetSiteList",
                    data: {
                        pageIndex: 1,
                    },
                    OnSuccess: function (data) {
                        self.SitetableData = data.data.Data;
                    }
                };
                http.Post(op);
            },

            getdistance(lat,lng) {
                var self = this;
                var rt;
                if (self.SitetableData.length == 0 || lat == undefined || lng == undefined) {
                    rt = "未知";
                } else {
                    var lat0 = self.SitetableData[0].Position.split(',')[1];
                    var lng0 = self.SitetableData[0].Position.split(',')[0];
                    rt = lat0 && lng0 && lat && lng && (util.getFlatternDistance(lat0, lng0, lat, lng) / 1000).toFixed(1).toString() + "Km" || "未知";
                }
                return rt;
            },

        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
            var self = this;
            self.IntroducerID = this.$route.query.IntroducerID;
            self.ShareID = this.$route.query.ShareID;
            if (!util.isBlank(this.$route.query.BeginDate) && !util.isBlank(this.$route.query.EndDate)) {
                self.BeginTime = this.$route.query.BeginDate;
                self.EndTime = this.$route.query.EndDate;
                this.Time = [moment(self.BeginTime, 'YYYY-MM-DD'), moment(self.EndTime, 'YYYY-MM-DD')];
            }
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
            this.getSite();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<style scoped>
    .imgPreview {
        border-radius: 4px;
        width: 50px;
        height: 50px;
    }
</style>